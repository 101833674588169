.linear-progress {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  /* background: #bbdefb; */
  height: 7px;
  background-color: rgba(255, 255, 255, 0.15);
  position: relative;
  width: 50%;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: hidden;
  border-radius: 4px !important;
  -webkit-animation: start 0.3s ease-in;
  animation: start 0.3s ease-in;
}
.linear-progress .bar {
  background-color: rgba(255, 255, 255, 0.15);
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  position: absolute;
  background: #1565c0;
  transition: transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px !important;
  bottom: 0;
  width: 100%;
}
.linear-progress .bar1 {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-animation: progressLinearMovement 2.5s infinite;
  animation: progressLinearMovement 2.5s infinite;
  -webkit-animation-delay: 0;
  border-radius: 4px !important;
  animation-delay: 0;
}
.linear-progress .bar2 {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  background-color: rgba(255, 255, 255, 0.15);
  left: -100%;
  -webkit-animation: progressLinearMovement 2.5s infinite;
  animation: progressLinearMovement 2.5s infinite;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
  border-radius: 4px !important;
}
.linear-progress-material {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  height: 7px;
  background-color: rgba(255, 255, 255, 0.15);
  position: relative;
  width: 75%;
  margin: 0 auto;
  margin-top: 2rem;
  overflow: hidden;
  -webkit-animation: start 0.3s ease-in;
  animation: start 0.3s ease-in;
  border-radius: 4px !important;
}
.linear-progress-material .bar {
  position: absolute;
  background-color: #fe2b4a;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  border-radius: 4px !important;
  transition: transform 0.2s linear;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.linear-progress-material .bar1 {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  -webkit-animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
  animation: growBar1 2.5s infinite, moveBar1 2.5s infinite;
}
.linear-progress-material .bar2 {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.25) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.25) 50%, rgba(0, 0, 0, 0.25) 75%, transparent 75%, transparent);
  -webkit-animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
  animation: growBar2 2.5s infinite, moveBar2 2.5s infinite;
}
@-webkit-keyframes growBar1 {
  0% {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    transform: scaleX(0.1);
  }
  36.6% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    transform: scaleX(0.1);
  }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    transform: scaleX(0.83);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes growBar1 {
  0% {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    transform: scaleX(0.1);
  }
  36.6% {
    -webkit-animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    transform: scaleX(0.1);
  }
  69.15% {
    -webkit-animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    animation-timing-function: cubic-bezier(0.22573, 0, 0.23365, 1.37098);
    transform: scaleX(0.83);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@-webkit-keyframes moveBar1 {
  0% {
    left: -105.16667%;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  20% {
    left: -105.16667%;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
  }
  69.15% {
    left: 21.5%;
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
  }
  100% {
    left: 95.44444%;
  }
}
@keyframes moveBar1 {
  0% {
    left: -105.16667%;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  20% {
    left: -105.16667%;
    -webkit-animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
  }
  69.15% {
    left: 21.5%;
    -webkit-animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
  }
  100% {
    left: 95.44444%;
  }
}
@-webkit-keyframes growBar2 {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    transform: scaleX(0.1);
  }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    transform: scaleX(0.57);
  }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    transform: scaleX(0.91);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes growBar2 {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    transform: scaleX(0.1);
  }
  19.15% {
    -webkit-animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    transform: scaleX(0.57);
  }
  44.15% {
    -webkit-animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    transform: scaleX(0.91);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@-webkit-keyframes moveBar2 {
  0% {
    left: -54.88889%;
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
  }
  25% {
    left: -17.25%;
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
  }
  48.35% {
    left: 29.5%;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
  }
  100% {
    left: 117.38889%;
  }
}
@keyframes moveBar2 {
  0% {
    left: -54.88889%;
    -webkit-animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40968);
  }
  25% {
    left: -17.25%;
    -webkit-animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73372);
  }
  48.35% {
    left: 29.5%;
    -webkit-animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
    animation-timing-function: cubic-bezier(0.4, 0.62703, 0.6, 0.90203);
  }
  100% {
    left: 117.38889%;
  }
}
@-webkit-keyframes start {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 20px;
    opacity: 1;
  }
}
@keyframes start {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 20px;
    opacity: 1;
  }
}
@-webkit-keyframes end {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 2px;
    opacity: 1;
  }
}
@keyframes end {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 2px;
    opacity: 1;
  }
}
@-webkit-keyframes progressLinearMovement {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}
@keyframes progressLinearMovement {
  0% {
    left: -100%;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 100%;
  }
}


.achievmentLoaderText{
  font-size: 13px !important;
  margin-top: 8px;
}