.profileWarpBar {
  padding: 2px !important;
}

.rateFlexContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "kanit";
}

.rateText {
  color: #fff !important;
  z-index: 999;
  font-size: 16px;
  font-family: kanit;
  letter-spacing: 0.06em;
}

.profileContainer {
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
  /* width: 768px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardFrameProfile {
  position: absolute;
  bottom: -10px;
}

/* .profileProgressBar {
  width: 100% !important;
} */

.notCompleted {
  background: #f6f6f6;
  opacity: 0.14;
}

.rateTextOp {
  color: rgba(255, 255, 255, 0.6) !important;
}

.flexColumnProfile {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.profileNameStyle {
  font-family: 'chakra-petch';
  font-style: normal;
  font-weight: 400;
  font-size: 34px;
  color: #fff !important;
  letter-spacing: 0.09em;
  z-index: 999;
  /* margin-bottom: 50px; */
}

.telegramUsername {
  font-family: 'chakra-petch';
  font-style: normal;
  font-size: 44px;
  /* line-height: 57; */
}

.profileTopFlex {
  margin-top: 20px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.viewLeaderBoardButton {
  width: 188px;
  height: 39px;
  background: #16181c;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
}

.profileTopFlex .clock {
  margin-bottom: 0px;
}

.profileTopFlex .clock .flexRow > span {
  font-size: 24px;
}

.profileTopFlex .clock .segment strong {
  font-size: 24px;
}
.profileTopFlex .clock .segment span {
  font-size: 12px;
  bottom: 10px;
}

.profileTopFlex .flexRow {
  /* width: 264px; */
}

.score {
  width: 522px;
}

.userFrameCi {
  border-radius: 50%;
  border: 3px solid #fff;
  width: 142px;
  height: 142px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  position: absolute;
  bottom: 240px;
  left: 50px;
}

.participant .avatarPlaceholder {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border-radius: 50%;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
