.heroContainer {
  position: relative;
  margin: 0 auto;
  max-width: 1250px;
  width: 100%;
}

.heroNFTname {
  color: #fff;
  font-family: "chakra-petch";
  font-weight: 600;
  font-size: 82px;
  line-height: 75px;
  text-transform: uppercase;
  margin-left: auto;
}

.heroCollectionStyle {
  position: absolute;
  left: 64%;
  right: auto;
  bottom: -105px;
}

.heroCollectionStyle img {
  width: 773px;
  height: 826px;
}

.heroButtonsSection,
.heroTimeContainer {
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.howWorkButtonStyle {
  width: 151px;
  height: 57px;
  background: rgba(32, 35, 41);
  margin-left: 21px;
  text-align: center;
  white-space: nowrap;
}
button.howWorkButtonStyle:hover {
  background: white;
  color: var(--secondary);
}
/* button > .howWorkButtonStyle  > .HOWITWORKS:hover {
  color: var(--secondary);
} */


.some {
  margin-left: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
}

.explorer {
  margin-bottom: 40px;
  text-align: center;
  white-space: nowrap;
  background: rgba(32, 35, 41);
  display: flex;
  align-items: center;
  justify-content: center;
}

.HOWITWORKS {
  margin-left: 6px;
}

.heroTimeContainer {
  margin-top: 76px;
}
