@font-face {
  font-family: "chakra-petch";
  src: url(../fonts/ChakraPetch-Regular.ttf);
}

@font-face {
  font-family: "chakra-petch-semibold";
  src: url(../fonts/ChakraPetch-SemiBold.ttf);
}

@font-face {
  font-family: "rajdhani";
  src: url(../fonts/Rajdhani-Regular.ttf);
}

@font-face {
  font-family: "inter";
  src: url(../fonts/Inter-Regular.ttf);
}

@font-face {
  font-family: "kanit";
  src: url(../fonts/Kanit-Regular.ttf);
}

@font-face {
  font-family: "digital";
  src: url(../fonts/digital-7.ttf);
}

/*
@font-face {
  font-family: "albertus-nova";
  src: url(../fonts/AlbertusNova.ttf);
}

@font-face {
  font-family: "albertus-nova-bold";
  src: url(../fonts/AlbertusNova-Bold.ttf);
}

@font-face {
  font-family: "cabazon";
  src: url(../fonts/CabazonRegular.ttf);
}

@font-face {
  font-family: "black-rose";
  src: url(../fonts/Black\ Rose.ttf);
}*/
