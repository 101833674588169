.bridgeTitle {
  font-family: "chakra-petch";
  font-size: 44px;
  text-align: center;
  padding-bottom: 30px;
}

.bridgePopupHeaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeBridgeButton {
  width: 58px;
  height: 58px;
  opacity: 0.2;
  border: 1px solid #ffffff;
}

.bridgeContainer {
  min-height: 500px;
  position: relative;
}

.lds-ellipsis {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-70%, -50%);
}
